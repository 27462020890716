import BaseDto from "@/main/Dtos/BaseDto";
import ReceivedAccountDto from "@/main/Dtos/ReceivedAccountDto";

interface CreateCashTransferDtoProps {
  lat: number;
  lng: number;
  address: string;
  received_accounts: Array<ReceivedAccountDto>;
  funds_list: Array<object>;
  user_lng: number;
  user_lat: number;
}

export default class CreateCashTransferDto
  extends BaseDto
  implements CreateCashTransferDtoProps
{
  lat = 0;
  lng = 0;
  address = "";
  received_accounts = [];
  funds_list = [];
  user_lng = 0;
  user_lat = 0;
}
