import mqtt from "paho-mqtt";
import { Store } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { clearLoginInfo } from "@/main/ts/auth";
import Notify from "@/core/helpers/notify";
import { StoreInterface } from "@/store";
import OrderEnums from "@/main/enums/OrderEnums";
import { convertProxyObjectToPojo } from "@/core/helpers/utils";
import OrderInterface from "@/main/models/interfaces/OrderInterface";

export interface MqttProps {
  client: mqtt.Client;
  store: Store<StoreInterface>;
  isFirstMessage: boolean;

  broadcastLogin(id: number): void;
  subscribe(): void;
  unsubscribe(preventBroadcast?: boolean): void;
}

class MqttClient implements MqttProps {
  client: mqtt.Client;
  store: Store<StoreInterface>;
  isFirstMessage = true;
  constructor(store: Store<StoreInterface>) {
    this.store = store;
    this.client = new mqtt.Client(
      `${process.env.VUE_APP_MQTT_CLIENT_HOST}`,
      parseInt(`${process.env.VUE_APP_MQTT_CLIENT_PORT}`),
      `client_web_${
        new Date().getTime() + parseInt((Math.random() * 100).toString())
      }`
    );
    this.client.onConnectionLost = this._onConnectionLost;
    this.client.onMessageArrived = this._onMessageArrived;
  }

  public connect() {
    this.client.connect({
      userName: process.env.VUE_APP_MQTT_CLIENT_USERNAME,
      password: process.env.VUE_APP_MQTT_CLIENT_PASSWORD,
      useSSL: true,
      reconnect: true,
      onSuccess: this._onConnectSuccess,
      onFailure: this._onConnectFail,
    });
  }

  public subscribe() {
    const topic = this._getTopic();
    if (topic.length > 0) {
      this.client.unsubscribe(topic, {});
      this.client.subscribe(topic, {
        qos: 1,
        onSuccess: () => {
          console.log("mqtt_subscribeToTopic", topic);
        },
      });
    }
  }

  public unsubscribe(preventBroadcast?: boolean) {
    const topic = this._getTopic();
    if (topic.length > 0) {
      try {
        if (!preventBroadcast) {
          this.broadcastLogout();
        }
        this.client.unsubscribe(topic, {
          onSuccess: () => {
            this.isFirstMessage = true;
          },
        });
      } catch (e: any) {
        console.log("Unsubscribe Topic failed");
      }
    }
  }

  public broadcastLogout = () => {
    if (this.client) {
      this.client.send(
        this._getTopic(),
        JSON.stringify({
          t: "logout-other-tab",
          d: this.store.state.auth.deviceId,
        }),
        0,
        false
      );
    }
  };

  public broadcastLogin = (id: number) => {
    if (this.client) {
      const deviceId = this.store.state.auth.deviceId + "-" + id;
      const topic = this._getTopic();
      this.client.send(
        topic,
        JSON.stringify({
          t: "login-from-another-device",
          d: deviceId,
          os: "web",
        }),
        0,
        false
      );
      setTimeout(() => {
        this.subscribe();
      }, 1000);
    }
  };

  private _onConnectionLost = (error): void => {
    console.log("mqtt_onConnectionLost", error);
    this._reconnectMqtt();
  };

  private _reconnectMqtt(): void {
    setTimeout(() => {
      this.isFirstMessage = true;
      this.connect();
    }, 1000);
  }

  private _onMessageArrived = (message: mqtt.Message): void => {
    const data = JSON.parse(message.payloadString);
    if (process.env.VUE_APP_ENV != "production") {
      console.log(message, data);
    }
    if (this.isFirstMessage) {
      this.isFirstMessage = false;
      return;
    }
    switch (data.t) {
      case "order-system-received":
      case "order-cancelled":
      case "order-updated":
      case "order-completed":
      case "order-changed":
      case "order-system-transferred":
      case "order-confirm-received":
      case "order-agent-changed":
        this._checkUpdateOrder(data.d);
        break;
      case "login-from-another-device":
        if (data.os != undefined && data.os == "web") {
          const deviceId = data.d.split("-")[0];
          if (deviceId == this.store.state.auth.deviceId) {
            return;
          }
        }
        Notify.error("Tài khoản được đăng nhập trên thiết bị khác!");
        setTimeout(() => {
          clearLoginInfo(this);
        }, 2000);
        break;
      case "logout-other-tab":
        if (
          data.d == this.store.state.auth.deviceId &&
          this.store.state.auth.accessToken
        ) {
          clearLoginInfo(this, true);
        }
        break;
    }
  };

  private _checkUpdateOrder = (detail: OrderInterface): void => {
    const order = this.store.state.order.detail;
    const listProcessing = this.store.state.order.listProcessing;
    if (order && order.detail.id == detail.id) {
      this.store.dispatch(Actions.UPDATE_ORDER_DETAIL, {
        detail: convertProxyObjectToPojo(detail),
      });
    }
    if (detail) {
      this.store.dispatch(Actions.UPDATE_PROCESS_ORDER, {
        detail: convertProxyObjectToPojo(detail),
      });
      if (
        [
          OrderEnums.OrderStatuses.COMPLETED,
          OrderEnums.OrderStatuses.CANCELLED,
        ].indexOf(detail.status) != -1
      ) {
        if (listProcessing.indexOf(detail.id) != -1) {
          listProcessing.splice(listProcessing.indexOf(detail.id), 1);
          this.store.commit("order/" + Mutations.UPDATE_LIST_PROCESSING, {
            listProcessing: listProcessing,
          });
        }
      } else {
        if (listProcessing.indexOf(detail.id) == -1) {
          listProcessing.push(detail.id);
          this.store.commit("order/" + Mutations.UPDATE_LIST_PROCESSING, {
            listProcessing: listProcessing,
          });
        }
      }
    }
  };

  private _onConnectSuccess = (): void => {
    console.log("mqtt connect success");
    this.subscribe();
  };

  private _onConnectFail = (error) => {
    console.log(error);
    console.log("mqtt connect failed");
    this._reconnectMqtt();
  };

  private _getTopic() {
    if (this.store.state.auth.user) {
      return `${process.env.VUE_APP_MQTT_CLIENT_KEY}/topic/${this.store.state.auth.user.id}/`;
    }
    return "";
  }
}

let instance: MqttClient;
export function initMqtt(store: Store<StoreInterface>): MqttProps {
  if (!instance) {
    instance = new MqttClient(store);
  }
  instance.connect();
  return instance;
}
