import { dateFormat, dateFromTimestamp } from "@/core/helpers/date";
import OrderEnums from "@/main/enums/OrderEnums";
import { numberWithCommas } from "@/core/helpers/utils";
import OrderInterface from "@/main/models/interfaces/OrderInterface";

export default class Order {
  public detail: OrderInterface;
  constructor(order: OrderInterface) {
    this.detail = order;
    if (this.detail.funds != undefined && this.detail.funds.length > 0) {
      for (let i = 0; i < this.detail.funds.length; i++) {
        const fund: any = this.detail.funds[i];
        this.detail.funds[i]["total"] = fund.funds.reduce(
          (accumulator, item) => {
            return accumulator + item.amount;
          },
          0
        );
        this.detail.funds[i]["date"] = dateFormat(
          new Date(this.detail.funds[i]["date"]),
          "d/m/Y"
        );
        this.detail.funds[i]["detail"] = fund.funds;
      }
    }
  }

  public getDisplayTitle(): string {
    return [this.detail.title].join(" - ");
  }

  public getDisplayTimestamp(): string {
    return dateFromTimestamp(this.detail.created_at, "H:i:s d/m/Y");
  }

  public getTitleByType(): string {
    switch (this.detail.type) {
      case OrderEnums.OrderTypes.WITHDRAW:
        return "Rút tiền mặt";
      case OrderEnums.OrderTypes.CASH_TRANSFER:
        return "Nộp tiền mặt";
      default:
        return "";
    }
  }

  public getDisplayStatus(): string {
    switch (this.detail.status) {
      case OrderEnums.OrderStatuses.COMPLETED:
        return "Hoàn thành";
      case OrderEnums.OrderStatuses.CANCELLED:
        return "Đã hủy";
      case OrderEnums.OrderStatuses.AGENT_ACCEPTED:
        return "Đã nhận";
      case OrderEnums.OrderStatuses.NEW:
      case OrderEnums.OrderStatuses.WAITING_AGENT:
        return "Đang tìm GDV";
    }
    if (
      this.detail.type == OrderEnums.OrderTypes.WITHDRAW &&
      !this.detail.arrived_at
    ) {
      return "Đã nhận";
    }
    return "Đang giao dịch";
  }

  public getStatusClass(): string {
    switch (this.detail.status) {
      case OrderEnums.OrderStatuses.COMPLETED:
        return "text-success border-success";
      case OrderEnums.OrderStatuses.CANCELLED:
        return "text-secondary border-secondary";
      case OrderEnums.OrderStatuses.NEW:
      case OrderEnums.OrderStatuses.WAITING_AGENT:
        return "text-warning border-warning";
      default:
        return "text-primary border-primary";
    }
  }

  public getOrderLogo(): string {
    switch (this.detail.type) {
      case OrderEnums.OrderTypes.WITHDRAW:
        return "withdraw.png";
      case OrderEnums.OrderTypes.CASH_TRANSFER:
        return "cash_transfer.png";
      default:
        return "";
    }
  }

  public getPriceText(): string {
    return numberWithCommas(this.detail.amount) + "đ";
  }

  public getOrderTitle(): string {
    let title = "";
    switch (this.detail.type) {
      case OrderEnums.OrderTypes.WITHDRAW:
        title = this._getOrderWithdrawTitle();
        break;
      case OrderEnums.OrderTypes.CASH_TRANSFER:
        title = this._getOrderCashTransferTitle();
        break;
    }
    return title;
  }

  private _getOrderWithdrawTitle(): string {
    if (
      [
        OrderEnums.OrderStatuses.NEW,
        OrderEnums.OrderStatuses.WAITING_AGENT,
      ].indexOf(this.detail.status) != -1
    ) {
      return "Đang tìm giao dịch viên";
    } else if (
      this.detail.status == OrderEnums.OrderStatuses.AGENT_ACCEPTED ||
      !this.detail.arrived_at
    ) {
      return "Giao dịch viên đang đến chỗ của bạn";
    } else if (this.detail.transferred_at && this.detail.arrived_at) {
      return "Xác nhận để hoàn thành giao dịch";
    } else if (
      [
        OrderEnums.OrderStatuses.WAIT_USER_TRANSFER,
        OrderEnums.OrderStatuses.QR_CODE_CREATED,
      ].indexOf(this.detail.status) != -1
    ) {
      return "Giao dịch viên đã đến nơi";
    }
    return "";
  }

  private _getOrderCashTransferTitle(): string {
    switch (this.detail.status) {
      case OrderEnums.OrderStatuses.NEW:
        return "Đang tìm giao dịch viên";
      case OrderEnums.OrderStatuses.AGENT_ACCEPTED:
        return "Giao dịch viên đang đến chỗ của bạn";
      case OrderEnums.OrderStatuses.WAIT_USER_TRANSFER:
      case OrderEnums.OrderStatuses.QR_CODE_CREATED:
        return "Giao dịch viên đã đến nơi";
      case OrderEnums.OrderStatuses.AGENT_RECEIVED:
        return "Vui lòng chờ xác nhận";
      case OrderEnums.OrderStatuses.AGENT_TRANSFERRED:
      case OrderEnums.OrderStatuses.SYSTEM_TRANSFERRED:
        return "Xác nhận để hoàn thành giao dịch";
      default:
        return "";
    }
  }

  public getOrderSteps(): Array<object> {
    let steps: Array<object> = [];
    switch (this.detail.type) {
      case OrderEnums.OrderTypes.WITHDRAW:
        steps = [
          {
            title: "Chuyển khoản cho hệ thống",
            desc: `<span class="text-black-50">Số tiền cần chuyển</span> <span class="text-success">${numberWithCommas(
              this.detail.gross_amount
            )}đ</span>`,
          },
          {
            title: "Nhận tiền mặt",
            desc: `<small class="text-black-50 lh-1">Kiểm đếm tiền đầy đủ và cung cấp OTP cho GDV để hoàn thành giao dịch</small>`,
          },
        ];
        if (
          this.detail.status == OrderEnums.OrderStatuses.USER_TRANSFERRED ||
          this.detail.transferred_at
        ) {
          steps[1][
            "desc"
          ] += ` <span class="text-danger">(<span class="count-down">120</span>s)</span><br>
              <h5 class="text-primary text-center fw-bold">${this.detail.accept_code}</h5>`;
        }
        break;
      case OrderEnums.OrderTypes.CASH_TRANSFER:
        steps = [
          {
            title: "Nộp tiền cho Giao dịch viên",
            desc: `<span class="text-black-50">Số tiền mặt cần giao</span> <span class="text-success">${numberWithCommas(
              this.detail.gross_amount
            )}đ</span>`,
          },
          {
            title: "Xác nhận giao dịch",
            desc: `<small class="text-black-50 lh-1">Cung cấp OTP cho GDV để hoàn thành giao dịch</small>`,
          },
        ];
        if (
          (!this.detail.is_transfer_late &&
            ((this.detail.require_confirm_completed &&
              [
                OrderEnums.OrderStatuses.SYSTEM_TRANSFERRED,
                OrderEnums.OrderStatuses.USER_CONFIRM_RECEIVED,
              ].indexOf(this.detail.status) != -1) ||
              (!this.detail.request_trans_via_system &&
                this.detail.agent.type == OrderEnums.AgentTypes.CP &&
                [
                  OrderEnums.OrderStatuses.AGENT_RECEIVED,
                  OrderEnums.OrderStatuses.AGENT_TRANSFERRED,
                ].indexOf(this.detail.status) != -1))) ||
          (this.detail.is_transfer_late &&
            ((OrderEnums.OrderStatuses.AGENT_TRANSFERRED ==
              this.detail.status &&
              this.detail.agent.type == OrderEnums.AgentTypes.CP) ||
              (OrderEnums.OrderStatuses.AGENT_RECEIVED == this.detail.status &&
                this.detail.agent.type == OrderEnums.AgentTypes.SIH)))
        ) {
          steps[1][
            "desc"
          ] += ` <span class="text-danger">(<span class="count-down">120</span>s)</span><br>
                <h5 class="text-primary text-center fw-bold">${this.detail.accept_code}</h5>`;
        }
        break;
    }
    return steps;
  }

  public getOrderCurrentStep(): number {
    let currentStep = 0;
    switch (this.detail.type) {
      case OrderEnums.OrderTypes.WITHDRAW:
        currentStep = this._getCurrentOrderStepWithdraw();
        break;
      case OrderEnums.OrderTypes.CASH_TRANSFER:
        currentStep = this._getCurrentOrderStepCashTransfer();
        break;
    }
    return currentStep;
  }

  public getOrderProcessingTitle = (): string => {
    if (
      [
        OrderEnums.OrderStatuses.NEW,
        OrderEnums.OrderStatuses.WAITING_AGENT,
      ].indexOf(this.detail.status) != -1
    ) {
      return `Đang tìm giao dịch viên...`;
    }
    if (this.detail.type == OrderEnums.OrderTypes.WITHDRAW) {
      if (this.detail.arrived_at && this.detail.transferred_at) {
        return "Xác nhận giao dịch";
      }
      return `${this.detail.agent.full_name} - ${this.detail.agent.phone_number}`;
    } else {
      if (this.detail.is_transfer_late) {
        if (
          this.detail.request_trans_via_system &&
          this.detail.status == OrderEnums.OrderStatuses.AGENT_RECEIVED
        ) {
          return "Hệ thống đang xử lý...";
        }
        if (
          [
            OrderEnums.OrderStatuses.AGENT_RECEIVED,
            OrderEnums.OrderStatuses.AGENT_TRANSFERRED,
            OrderEnums.OrderStatuses.AGENT_CONFIRM_TRANSFERRED,
          ].indexOf(this.detail.status) != -1
        ) {
          if (
            this.detail.require_confirm_completed &&
            !this.detail.confirm_complete_at
          ) {
            return "Xác nhận giao dịch";
          }
          return "Nộp tiền thành công";
        }
      } else {
        if (
          this.detail.status == OrderEnums.OrderStatuses.SYSTEM_TRANSFERRED ||
          this.detail.status ==
            OrderEnums.OrderStatuses.USER_CONFIRM_RECEIVED ||
          ([
            OrderEnums.OrderStatuses.AGENT_RECEIVED,
            OrderEnums.OrderStatuses.AGENT_TRANSFERRED,
          ].indexOf(this.detail.status) != -1 &&
            this.detail.agent.type == OrderEnums.AgentTypes.CP &&
            !this.detail.request_trans_via_system)
        ) {
          return "Xác nhận giao dịch";
        } else if (
          [
            OrderEnums.OrderStatuses.AGENT_RECEIVED,
            OrderEnums.OrderStatuses.AGENT_TRANSFERRED,
            OrderEnums.OrderStatuses.AGENT_CONFIRM_TRANSFERRED,
          ].indexOf(this.detail.status) != -1
        ) {
          return "Hệ thống đang xử lý...";
        }
      }
      return `${this.detail.agent.full_name} - ${this.detail.agent.phone_number}`;
    }
    return "";
  };

  public getOrderProcessingDesc = (): string => {
    if (
      [
        OrderEnums.OrderStatuses.NEW,
        OrderEnums.OrderStatuses.WAITING_AGENT,
      ].indexOf(this.detail.status) != -1
    ) {
      return `Khoảng <span class="text-success">2 phút</span>`;
    }
    if (this.detail.type == OrderEnums.OrderTypes.WITHDRAW) {
      if (
        this.detail.status == OrderEnums.OrderStatuses.AGENT_ACCEPTED ||
        !this.detail.arrived_at
      ) {
        return `Giao dịch viên đang đến chỗ của bạn`;
      } else if (
        this.detail.status == OrderEnums.OrderStatuses.USER_TRANSFERRED ||
        this.detail.transferred_at
      ) {
        return "Vui lòng xác nhận";
      }
      return "Đang giao dịch";
    } else {
      const title = this.getOrderProcessingTitle();
      if (title == "Xác nhận giao dịch") {
        return "Vui lòng xác nhận";
      } else if (title == "Hệ thống đang xử lý...") {
        return "Đang xác nhận";
      } else if (title == "Nộp tiền thành công") {
        return "Gutina đã nhận tiền";
      }
      if (
        [
          OrderEnums.OrderStatuses.QR_CODE_CREATED,
          OrderEnums.OrderStatuses.WAIT_USER_TRANSFER,
        ].indexOf(this.detail.status) != -1
      ) {
        return "Đang giao dịch";
      }
      return this.getOrderTitle();
    }
    return "";
  };

  private _getCurrentOrderStepWithdraw(): number {
    let currentStep = 0;
    switch (this.detail.status) {
      case OrderEnums.OrderStatuses.WAIT_USER_TRANSFER:
        currentStep = 0;
        break;
      default:
        currentStep = 1;
    }
    return currentStep;
  }

  private _getCurrentOrderStepCashTransfer(): number {
    let currentStep = 0;
    if (this.detail.is_transfer_late) {
      if (
        (OrderEnums.OrderStatuses.AGENT_TRANSFERRED == this.detail.status &&
          this.detail.agent.type == OrderEnums.AgentTypes.CP) ||
        (OrderEnums.OrderStatuses.AGENT_RECEIVED == this.detail.status &&
          this.detail.agent.type == OrderEnums.AgentTypes.SIH)
      ) {
        currentStep = 1;
      }
    } else {
      if (
        [
          OrderEnums.OrderStatuses.USER_CONFIRM_RECEIVED,
          OrderEnums.OrderStatuses.SYSTEM_TRANSFERRED,
        ].indexOf(this.detail.status) != -1 ||
        (!this.detail.request_trans_via_system &&
          this.detail.agent.type == OrderEnums.AgentTypes.CP &&
          [
            OrderEnums.OrderStatuses.AGENT_RECEIVED,
            OrderEnums.OrderStatuses.AGENT_TRANSFERRED,
          ].indexOf(this.detail.status) != -1)
      ) {
        currentStep = 1;
      } else if (
        [
          OrderEnums.OrderStatuses.AGENT_RECEIVED,
          OrderEnums.OrderStatuses.AGENT_TRANSFERRED,
        ].indexOf(this.detail.status) != -1
      ) {
        currentStep = 0.5;
      }
    }
    return currentStep;
  }
}
