import { Storages } from "@/main/enums/StorageEnums";
import StorageClass from "@/core/helpers/storage";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Mutations } from "@/store/enums/StoreEnums";
import AuthState from "@/store/modules/AuthModule/interfaces/AuthState";
import UserProps from "@/store/modules/AuthModule/interfaces/UserProps";

@Module({ namespaced: true })
export default class AuthModule extends VuexModule implements AuthState {
  accessToken = StorageClass.getValue(Storages.ACCESS_TOKEN);
  deviceId = StorageClass.getValue(Storages.DEVICE_ID);
  secretKey = StorageClass.getValue(Storages.SECRET_KEY);
  user = StorageClass.getUser();

  @Mutation
  [Mutations.UPDATE_ACCESS_TOKEN](accessToken: string | null): void {
    this.accessToken = accessToken;
    if (accessToken) {
      StorageClass.setValue(Storages.ACCESS_TOKEN, accessToken);
    } else {
      StorageClass.deleteValue(Storages.ACCESS_TOKEN);
    }
  }

  @Mutation
  [Mutations.UPDATE_USER](user: UserProps | null): void {
    this.user = user;
    if (user) {
      StorageClass.setUser(user);
    } else {
      StorageClass.deleteValue(Storages.USER);
    }
  }

  @Mutation
  [Mutations.UPDATE_DEVICE_ID](deviceId: string | null): void {
    this.deviceId = deviceId;
    if (deviceId) {
      StorageClass.setValue(Storages.DEVICE_ID, deviceId);
    } else {
      StorageClass.deleteValue(Storages.DEVICE_ID);
    }
  }

  @Mutation
  [Mutations.UPDATE_SECRET_KEY](secretKey: string | null): void {
    this.secretKey = secretKey;
    if (secretKey) {
      StorageClass.setValue(Storages.SECRET_KEY, secretKey);
    } else {
      StorageClass.deleteValue(Storages.SECRET_KEY);
    }
  }
}
