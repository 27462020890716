import $ from 'jquery'

export function togglePassword(e): void {
    const target = $(e.target)
    const passwordField = $(e.target).parent().prev()
    if (target.hasClass('fa-eye')) {
        target.removeClass('fa-eye').addClass('fa-eye-slash')
        passwordField.prop('type', 'text')
    } else {
        target.removeClass('fa-eye-slash').addClass('fa-eye')
        passwordField.prop('type', 'password')
    }
}