import BaseDto from "@/main/Dtos/BaseDto";

interface CheckFeeDtoProps {
  lng: number;
  lat: number;
  type: number;
  price: number;
  receive_account_number: number;
}

export default class CheckFeeDto extends BaseDto implements CheckFeeDtoProps {
  lat = 0;
  lng = 0;
  price = 0;
  receive_account_number = 0;
  type = 0;
}
