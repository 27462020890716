import { Mutations } from "@/store/enums/StoreEnums";
import Order from "@/main/models/Order";
import OrderEnums from "@/main/enums/OrderEnums";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import OrderState from "@/store/modules/OrderModule/interfaces/OrderState";

@Module({ namespaced: true })
export default class OrderModule extends VuexModule implements OrderState {
  list = [];
  detail = {} as Order | null;
  orderSystemReceived = {} as Order | null;
  transAddresses = [];
  listProcessing = Array<number>();

  @Mutation
  [Mutations.UPDATE_ORDER_LIST](payload) {
    this.list = this.list.concat(payload.orders);
  }

  @Mutation
  [Mutations.UPDATE_ORDER_DETAIL](payload) {
    this.detail = new Order(payload.detail);
  }

  @Mutation
  [Mutations.CLEAR_ORDER_LIST]() {
    this.list = [];
  }

  @Mutation
  [Mutations.UPDATE_PROCESS_ORDER](payload) {
    if (
      payload.detail &&
      [
        OrderEnums.OrderStatuses.COMPLETED,
        OrderEnums.OrderStatuses.CANCELLED,
      ].indexOf(payload.detail.status) == -1
    ) {
      this.orderSystemReceived = new Order(payload.detail);
    } else {
      this.orderSystemReceived = null;
    }
  }

  @Mutation
  [Mutations.UPDATE_TRANS_ADDRESSES](payload) {
    this.transAddresses = payload.addresses;
  }

  @Mutation
  [Mutations.UPDATE_LIST_PROCESSING](payload) {
    this.listProcessing = payload.listProcessing;
  }
}
