import axios from "axios";
import router from "@/router";
import { clearLoginInfo, setLoginInfo } from "@/main/ts/auth";
import { ErrorCodes, StatusCodes } from "@/main/enums/ServiceEnums";
import Notify from "@/core/helpers/notify";
import store, { StoreInterface } from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import HashHelper from "@/core/helpers/hash";
import { Store } from "vuex";
import { MqttProps } from "@/core/services/MqttClient";
import CheckFeeDto from "@/main/Dtos/CheckFeeDto";
import UserProps from "@/store/modules/AuthModule/interfaces/UserProps";

class UserService {
  public static store: Store<StoreInterface>;
  public static mqttClient: MqttProps;

  public static init(store: Store<StoreInterface>, mqttClient: MqttProps) {
    this.store = store;
    this.mqttClient = mqttClient;
  }

  public static generateUrl(path): string {
    return process.env.VUE_APP_USER_API_URL + path;
  }

  public static setHeader(data = {}): void {
    axios.defaults.headers.common["device-id"] = this.store.state.auth.deviceId;
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.store.state.auth.accessToken;
    if (Object.keys(data).length > 0) {
      axios.defaults.headers.common["hash"] = HashHelper.encode(data);
    } else {
      delete axios.defaults.headers.common["hash"];
    }
  }

  public static registerDevice(callback): void {
    axios
      .post(this.generateUrl("/v2/devices/register"), {
        device_name: window.navigator.userAgent,
        device_id: this.store.state.auth.deviceId,
        device_os: "web",
        app_version: 1,
      })
      .then((response) => {
        const data = this.handleResponse(response);
        this.store.dispatch(Actions.UPDATE_SECRET_KEY, data.data.secret_key);
        callback();
      })
      .catch((error) => Notify.error(error.message));
  }

  public static login(data): void {
    this.registerDevice(() => {
      this.setHeader();
      axios
        .post(this.generateUrl("/v3/users/login"), data)
        .then((response) => {
          const data = this.handleResponse(response);
          if (data.result == StatusCodes.SUCCESS) {
            Notify.success("Đăng nhập thành công!", "Đăng nhập");
            setLoginInfo(data);
            this.mqttClient.broadcastLogin(data.data.user.id);
            setTimeout(() => {
              Notify.remove();
              router.push("dashboard");
            }, 500);
          } else {
            this.store.dispatch(Actions.UPDATE_ERROR, {
              message: "Tài khoản hoặc mật khẩu không hợp lệ!",
            });
          }
        })
        .catch(() => {
          this.store.dispatch(Actions.UPDATE_ERROR, {
            message: "Tài khoản hoặc mật khẩu không hợp lệ!",
          });
        });
    });
  }

  public static logout(): void {
    this.setHeader();
    axios
      .post(this.generateUrl("/v2/users/logout"))
      .then((response) => {
        const data = this.handleResponse(response);
        if (data.result == 1) {
          clearLoginInfo(this.mqttClient);
        } else {
          console.log(data);
        }
      })
      .catch(this._handleError);
  }

  public static changePassword(data, callback): void {
    this.setHeader(data);
    axios
      .post(this.generateUrl("/v2/users/change-password"), data)
      .then((response) => {
        const data = this.handleResponse(response);
        if (data.result == StatusCodes.SUCCESS) {
          Notify.success("Đổi mật khẩu thành công!", "Đổi mật khẩu");
          setLoginInfo(data);
          callback();
        } else {
          this.store.dispatch(Actions.UPDATE_ERROR, {
            message: data.message,
          });
        }
      })
      .catch(this._handleError);
  }

  public static getOrders(data, callback): void {
    this.setHeader(data);
    axios
      .post(this.generateUrl("/v2/orders"), data)
      .then((response) => {
        callback(response);
      })
      .catch(this._handleError);
  }

  public static getBanks(callback): void {
    this.setHeader();
    axios
      .post(this.generateUrl("/v2/banks"))
      .then((response) => {
        const data = this.handleResponse(response);
        if (data.result == StatusCodes.SUCCESS) {
          callback(data.data);
        } else {
          Notify.error(data.message);
        }
      })
      .catch(this._handleError);
  }

  public static getTransferAccount(callback): void {
    this.setHeader();
    axios
      .post(this.generateUrl("/v2/banks/transfer-accounts"))
      .then((response) => {
        const data = this.handleResponse(response);
        if (data.result == StatusCodes.SUCCESS) {
          callback(data.data);
        } else {
          Notify.error(data.message);
        }
      })
      .catch(this._handleError);
  }

  public static createWithdrawOrder(data) {
    this.setHeader(data);
    axios
      .post(this.generateUrl("/v2/orders/withdraw"), data)
      .then((response) => {
        const data = this.handleResponse(response);
        if (data.result == StatusCodes.SUCCESS) {
          Notify.success("Tạo đơn thành công!");
          setTimeout(() => {
            Notify.remove();
            router.push(`/order/detail/${data.data.order.id}`);
          }, 300);
        } else {
          this.store.dispatch(Actions.UPDATE_ERROR, {
            message: data.message,
          });
        }
      })
      .catch(this._handleError);
  }

  public static createCashTransfer(data) {
    this.setHeader(data);
    axios
      .post(this.generateUrl("/v2/orders/cash-to-bank"), data)
      .then((response) => {
        const data = this.handleResponse(response);
        if (data.result == StatusCodes.SUCCESS) {
          Notify.success("Tạo đơn thành công!");
          setTimeout(() => {
            Notify.remove();
            router.push(`/order/detail/${data.data.order.id}`);
          }, 300);
        } else {
          this.store.dispatch(Actions.UPDATE_ERROR, {
            message: data.message,
          });
          Notify.error(data.message);
        }
      })
      .catch(this._handleError);
  }

  public static checkFee(data, callback) {
    const checkFeeDto = new CheckFeeDto();
    checkFeeDto.fromData(data);
    this.setHeader(checkFeeDto.toObject());
    axios
      .post(this.generateUrl("/v3/orders/check-fee"), checkFeeDto.toObject())
      .then((response) => {
        const data = this.handleResponse(response);
        if (data.result == StatusCodes.SUCCESS) {
          callback(data.data);
        } else {
          this.store.dispatch(Actions.UPDATE_ERROR, {
            message: data.message,
          });
        }
      })
      .catch(this._handleError);
  }

  public static getOrder(id, callback) {
    const data = { order_id: id };
    this.setHeader(data);
    axios
      .post(this.generateUrl("/v2/orders/detail"), data)
      .then((response) => {
        const data = this.handleResponse(response);
        if (data.result == StatusCodes.SUCCESS) {
          callback(data.data);
        } else {
          router.push({
            path: "/orders",
          });
        }
      })
      .catch(this._handleError);
  }

  public static getListFunds(callback) {
    this.setHeader();
    axios
      .post(this.generateUrl("/v2/orders/list-funds"))
      .then((response) => {
        const data = this.handleResponse(response);
        if (data.result == StatusCodes.SUCCESS) {
          callback(data.data);
        } else {
          Notify.error(data.message);
        }
      })
      .catch(this._handleError);
  }

  public static getOrderTransferAccounts(callback) {
    this.setHeader();
    axios
      .post(this.generateUrl("/v2/orders/trans-accounts"))
      .then((response) => {
        const data = this.handleResponse(response);
        if (data.result == StatusCodes.SUCCESS) {
          callback(data.data);
        } else {
          Notify.error(data.message);
        }
      })
      .catch(this._handleError);
  }

  public static getOrderProcess(callback): void {
    this.setHeader();
    axios
      .post(this.generateUrl("/v2/orders/list-process"))
      .then((response) => {
        const data = this.handleResponse(response);
        if (data.result == StatusCodes.SUCCESS) {
          callback(data.data);
        } else {
          Notify.error(data.message);
        }
      })
      .catch(this._handleError);
  }

  public static forgotPassword(data, callback): void {
    this.setHeader(data);
    axios
      .post(this.generateUrl("/v3/users/forgot-password"), data)
      .then((response) => {
        const data = this.handleResponse(response);
        if (data.result == StatusCodes.SUCCESS) {
          callback(data.data);
        } else {
          this.store.dispatch(Actions.UPDATE_ERROR, {
            message: data.message,
          });
        }
      })
      .catch(this._handleError);
  }

  public static resetPassword(data, callback): void {
    this.setHeader(data);
    axios
      .post(this.generateUrl("/v3/users/reset-password"), data)
      .then((response) => {
        const data = this.handleResponse(response);
        if (data.result == StatusCodes.SUCCESS) {
          Notify.success("Đổi mật khẩu thành công!");
          callback();
        } else {
          this.store.dispatch(Actions.UPDATE_ERROR, {
            message: data.message,
          });
        }
      })
      .catch(this._handleError);
  }

  public static confirmVerifyOtp(data, callback) {
    this.setHeader(data);
    axios
      .post(this.generateUrl("/v3/users/verify-otp"), data)
      .then((response) => {
        const data = this.handleResponse(response);
        if (data.result == StatusCodes.SUCCESS) {
          callback(data.data);
        } else {
          this.store.dispatch(Actions.UPDATE_ERROR, {
            message: data.message,
          });
        }
      })
      .catch(this._handleError);
  }

  public static uploadImage(file) {
    this.setHeader();
    const formData = new FormData();
    formData.append("file", file);
    axios
      .post(this.generateUrl("/v2/files/upload-image"), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const data = this.handleResponse(response);
        if (data.result == StatusCodes.SUCCESS) {
          if (this.store.state.auth.user) {
            const user: UserProps = this.store.state.auth.user;
            this.updateProfile({
              email: user.email,
              gender: user.gender,
              avatar: data.data,
              address: user.address,
              birthday: user.birth_day,
              full_name: user.full_name,
            });
          }
        } else {
          this.store.dispatch(Actions.UPDATE_ERROR, {
            message: data.message,
          });
        }
      })
      .catch(this._handleError);
  }

  public static updateProfile(data) {
    this.setHeader(data);
    axios
      .post(this.generateUrl("/v2/users/update-profile"), data)
      .then((response) => {
        const data = this.handleResponse(response);
        if (data.result == StatusCodes.SUCCESS) {
          Notify.success("Đổi avatar thành công!");
          store.dispatch(Actions.UPDATE_USER, data.data);
        } else {
          this.store.dispatch(Actions.UPDATE_ERROR, {
            message: data.message,
          });
        }
      })
      .catch(this._handleError);
  }

  public static userConfigs() {
    this.setHeader();
    return axios
      .post(this.generateUrl("/v2/configs/user"))
      .then((response) => {
        const data = this.handleResponse(response);
        if (data.result == StatusCodes.SUCCESS) {
          this.store.dispatch(Actions.UPDATE_USER_CONFIGS, {
            config: data.data,
          });
          return true;
        } else {
          this.store.dispatch(Actions.UPDATE_ERROR, {
            message: data.message,
          });
          return false;
        }
      })
      .catch(this._handleError);
  }

  public static getConfigs() {
    this.setHeader();
    return axios
      .post(this.generateUrl("/v2/configs"))
      .then((response) => {
        const data = this.handleResponse(response);
        if (data.result == StatusCodes.SUCCESS) {
          this.store.dispatch(Actions.UPDATE_CONFIGS, {
            config: data.data,
          });
        } else {
          this.store.dispatch(Actions.UPDATE_ERROR, {
            message: data.message,
          });
        }
      })
      .catch(this._handleError);
  }

  public static transAddress() {
    this.setHeader();
    return axios
      .post(this.generateUrl("/v2/orders/trans-addresses"))
      .then((response) => {
        const data = this.handleResponse(response);
        if (data.result == StatusCodes.SUCCESS) {
          this.store.dispatch(Actions.UPDATE_TRANS_ADDRESSES, {
            addresses: data.data,
          });
        } else {
          this.store.dispatch(Actions.UPDATE_ERROR, {
            message: data.message,
          });
        }
        return true;
      })
      .catch(this._handleError);
  }

  private static _handleError = (error) => {
    console.log(error);
    let message = error.message;
    if (error.response.data.message) {
      message = error.response.data.message;
    }
    this.store.dispatch(Actions.UPDATE_ERROR, {
      message: message,
    });
    Notify.error(message);
  };

  public static handleResponse(response) {
    const currentRoute = router.currentRoute.value;
    if (response.status == ErrorCodes.ERROR_AUTH_FAILED) {
      clearLoginInfo(this.mqttClient);
    } else if (response.status != ErrorCodes.OK) {
      let message = response.statusText;
      if (response.data.message != undefined) {
        message = response.data.message;
      }
      Notify.error(message);
      return;
    } else {
      const data = response.data;
      if (
        data.result == StatusCodes.FAILED_AUTH &&
        currentRoute.name != "password-change"
      ) {
        clearLoginInfo(this.mqttClient);
      }
      return data;
    }
  }
}

export default UserService;
