import BaseDto from "@/main/Dtos/BaseDto";

interface OrderFundDtoProps {
  total: number;
  detail: Array<object>;
  date: string;
}

export default class OrderFundDto extends BaseDto implements OrderFundDtoProps {
  total = 0;
  detail = [];
  date = "";

  public toFormData(): object {
    const data: {
      [code: number]: object;
    } = {};
    for (let i = 0; i < this.detail.length; i++) {
      const detail = this.detail[i];
      data[detail["code"]] = {
        amount: detail["amount"],
        note: detail["note"],
      };
    }
    return {
      funds: data,
      date: this.date,
    };
  }
}
