import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordName,
} from "vue-router";
import { clearLoginInfo } from "@/main/ts/auth";
import StorageClass from "@/core/helpers/storage";
import { Storages } from "@/main/enums/StorageEnums";
import jwt from "jsonwebtoken";
import { getDeviceID } from "@/main/plugins/FingerprintJS";
import router from "@/router";
import { Store } from "vuex";
import UserService from "@/main/services/UserService";
import Notify from "@/core/helpers/notify";
import { MqttProps } from "@/core/services/MqttClient";
import store, { StoreInterface } from "@/store";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export default class RouteMiddleware {
  public static store: Store<StoreInterface>;
  public static mqttClient: MqttProps;

  public static init = (
    store: Store<StoreInterface>,
    client: MqttProps
  ): void => {
    this.store = store;
    this.mqttClient = client;
  };

  public static validate = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): void => {
    const authRoutes = this._authRoute();
    // check login to redirect
    if (!this._isLoggedIn() && authRoutes.indexOf(to.name) == -1) {
      router.push("login");
    }
    // check device ID
    else if (!this._hasDeviceId()) {
      getDeviceID(() => {
        next();
      });
    }
    // check login to redirect
    else if (this._isLoggedIn() && authRoutes.indexOf(to.name) != -1) {
      router.push("dashboard");
    } else {
      // check config
      if (!this._hasConfig() && this._isLoggedIn()) {
        const getConfigPromise = UserService.getConfigs();
        getConfigPromise
          .then(() => {
            return UserService.userConfigs();
          })
          .then((success) => {
            if (success) {
              return UserService.transAddress();
            }
            return success;
          })
          .then((success) => {
            if (success) {
              this._validateRoute(to, next);
            }
          });
      } else {
        this._validateRoute(to, next);
      }
    }
  };

  public static afterNavigation(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized
  ): void {
    if (to.name == "login" && !!this.store.state.auth.user) {
      this._clearLoginInfo();
    }
    let pageTitle = to.meta.title || "Web Client";
    document.title = pageTitle + "";
    if (to.name == "order_detail") {
      pageTitle = to.params.id;
    }
    store.dispatch(Actions.UPDATE_PAGE_TITLE, {
      title: pageTitle,
    });
  }

  private static _validateRoute(
    to: RouteLocationNormalized,
    next: NavigationGuardNext
  ): void {
    const createOrderRoutes = this._createOrderRoutes();
    if (createOrderRoutes.indexOf(to.name) != -1 && !this._canCreateOrder(to)) {
      Notify.error(
        "Tài khoản của bạn không được phép tạo đơn. Vui lòng liên hệ quản trị viên",
        "Tính năng không khả dụng"
      );
    } else if (
      createOrderRoutes.indexOf(to.name) != -1 &&
      this.store.state.page.userConfigs.limit_orders_processing != 0 &&
      this.store.state.order.listProcessing.length ==
        this.store.state.page.userConfigs.limit_orders_processing
    ) {
      Notify.error("Cần hoàn thành đơn đang xử lý");
    } else {
      // Scroll page to top on every route change
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
      next();
    }
  }

  private static _clearLoginInfo = (): void => {
    store.dispatch(Actions.UPDATE_USER, null);
    store.dispatch(Actions.UPDATE_PROCESS_ORDER, {
      detail: null,
    });
    store.commit("order/" + Mutations.UPDATE_LIST_PROCESSING, {
      listProcessing: [],
    });
    store.commit("order/" + Mutations.CLEAR_ORDER_LIST);
    store.dispatch(Actions.UPDATE_USER_CONFIGS, {
      config: null,
    });
    store.commit("page/" + Mutations.UPDATE_CONFIGS, {
      config: null,
    });
    store.commit("page/" + Mutations.UPDATE_USER_CONFIGS, {
      config: null,
    });
    store.commit("order/" + Mutations.UPDATE_TRANS_ADDRESSES, {
      addresses: [],
    });
  };

  private static _hasConfig = (): boolean => {
    return this.store.state.page.hasConfig;
  };

  private static _canCreateOrder = (to): boolean => {
    const allowOrderTypes: Array<number> =
      this.store.state.page.userConfigs.allow_order_types;
    const user = this.store.state.auth.user;
    return (
      to.meta.type &&
      allowOrderTypes &&
      allowOrderTypes.indexOf(to.meta.type) != -1 &&
      user &&
      user.can_create_order
    );
  };

  private static _createOrderRoutes = (): Array<
    RouteRecordName | null | undefined
  > => {
    return ["create_cash_transfer", "create_withdraw"];
  };

  private static _isLoggedIn = (): boolean => {
    const accessToken = StorageClass.getValue(Storages.ACCESS_TOKEN);
    if (accessToken) {
      const decode = jwt.decode(accessToken);
      if (decode) {
        const expireDate = new Date(decode["expire_at"]);
        if (expireDate >= new Date()) {
          return true;
        } else {
          clearLoginInfo(this.mqttClient);
        }
      }
    }
    return false;
  };

  private static _authRoute = (): Array<RouteRecordName | null | undefined> => {
    return ["login", "forgot_password"];
  };

  private static _hasDeviceId = (): boolean => {
    return !!this.store.state.auth.deviceId;
  };
}
