export function dateFormat(dateObject, format = "Y-m-d"): string {
  if (!dateObject) {
    return "";
  }
  const day = updateTimeValue(dateObject.getDate());
  const month = updateTimeValue(dateObject.getMonth() + 1);
  const year = dateObject.getFullYear();
  const hour = updateTimeValue(dateObject.getHours());
  const minute = updateTimeValue(dateObject.getMinutes());
  const second = updateTimeValue(dateObject.getSeconds());
  switch (format) {
    case "Y-m-d H:i:s":
      return (
        [year, month, day].join("-") + " " + [hour, minute, second].join(":")
      );
    case "H:i:s d/m/Y":
      return (
        [hour, minute, second].join(":") + " " + [day, month, year].join("/")
      );
    case "d/m/Y":
      return [day, month, year].join("/");
    default:
      return [year, month, day].join("-");
  }
}

function updateTimeValue(value) {
  if (value < 10) {
    return "0" + value;
  }
  return value;
}

export function dateFromTimestamp(timestamp, format = "Y-m-d H:i:s"): string {
  if (!timestamp) {
    return "";
  }
  const dateObject = new Date(timestamp * 1000);
  return dateFormat(dateObject, format);
}
