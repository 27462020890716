import { createApp } from "vue";
import App from "./App.vue";

import router from "@/router";
import store from "@/store";
import CopyClipboard from "@/components/utils/CopyClipboard.vue";
import Datepicker from "@vuepic/vue-datepicker";
import GoogleMap from "@/components/utils/google/Map.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import { initMqtt } from "@/core/services/MqttClient";
import UserService from "@/main/services/UserService";
import RouteMiddleware from "@/router/middlewares/RouteMiddleware";

// connect mqtt
const mqttClient = initMqtt(store);

UserService.init(store, mqttClient);
RouteMiddleware.init(store, mqttClient);
// create app instance
const app = createApp(App);
app.use(router);
app.use(store);
app.component("CopyClipboard", CopyClipboard);
app.component("Datepicker", Datepicker);
app.component("GoogleMap", GoogleMap);
app.mount("#app");
