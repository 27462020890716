enum ErrorCodes {
  ERROR_BAD_REQUEST = 400,
  ERROR_AUTH_FAILED = 401,
  ERROR_AUTH_INVALID = 403,
  ERROR_NOT_FOUND = 404,
  OK = 200,
}

enum StatusCodes {
  FAILED_AUTH = 401,
  SUCCESS = 1,
}

export { ErrorCodes, StatusCodes };
