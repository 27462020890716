import BaseDto from "@/main/Dtos/BaseDto";

interface ReceivedAccountDtoProps {
  bank_code: string;
  account_name: string;
  account_number: string;
  price: number;
  note: string;
}

export default class ReceivedAccountDto
  extends BaseDto
  implements ReceivedAccountDtoProps
{
  bank_code = "";
  account_name = "";
  account_number = "";
  price = 0;
  note = "";
}
