import { Mutations } from "@/store/enums/StoreEnums";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import PageState from "@/store/modules/PageModule/interfaces/PageState";
import UserConfig from "@/store/modules/PageModule/interfaces/UserConfig";

@Module({ namespaced: true })
export default class PageModule extends VuexModule implements PageState {
  pageTitle = "Web Client";
  error = "";
  userConfigs = {} as UserConfig;
  configs = {};
  hasConfig = false;

  get hasError() {
    return !!this.error;
  }

  @Mutation
  [Mutations.UPDATE_PAGE_TITLE](payload): void {
    this.pageTitle = payload.title;
  }

  @Mutation
  [Mutations.UPDATE_ERROR](payload): void {
    this.error = payload.message;
  }

  @Mutation
  [Mutations.UPDATE_USER_CONFIGS](payload): void {
    this.userConfigs = payload.config;
  }

  @Mutation
  [Mutations.UPDATE_CONFIGS](payload): void {
    this.hasConfig = !!payload.config;
    this.configs = payload.config;
  }
}
