export default class BaseDto {
  public toObject(): object {
    return Object.assign({}, this);
  }

  public fromData(data): void {
    const props = Object.keys(this);
    for (let i = 0; i < props.length; i++) {
      if (data[props[i]] != undefined) {
        this[props[i]] = data[props[i]];
      }
    }
  }
}
