import { createStore, Store } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule/AuthModule";
import PageModule from "@/store/modules/PageModule/PageModule";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import OrderModule from "@/store/modules/OrderModule/OrderModule";

config.rawError = true;
export interface StoreInterface {
  auth: AuthModule;
  page: PageModule;
  order: OrderModule;
}

const store: Store<StoreInterface> = createStore({
  modules: {
    auth: AuthModule,
    page: PageModule,
    order: OrderModule,
  },
  actions: {
    [Actions.UPDATE_ACCESS_TOKEN](context, payload) {
      context.commit("auth/" + Mutations.UPDATE_ACCESS_TOKEN, payload);
    },
    [Actions.UPDATE_USER](context, payload) {
      context.commit("auth/" + Mutations.UPDATE_USER, payload);
    },
    [Actions.UPDATE_PAGE_TITLE](context, payload) {
      context.commit("page/" + Mutations.UPDATE_PAGE_TITLE, payload);
    },
    [Actions.UPDATE_ERROR](context, payload) {
      context.commit("page/" + Mutations.UPDATE_ERROR, payload);
      if (payload.message) {
        setTimeout(() => {
          context.dispatch(Actions.UPDATE_ERROR, {
            message: "",
          });
        }, 3000);
      }
    },
    [Actions.UPDATE_ORDER_LIST](context, payload) {
      context.commit("order/" + Mutations.UPDATE_ORDER_LIST, payload);
    },
    [Actions.UPDATE_ORDER_DETAIL](context, payload) {
      context.commit("order/" + Mutations.UPDATE_ORDER_DETAIL, payload);
    },
    [Actions.UPDATE_PROCESS_ORDER](context, payload) {
      context.commit("order/" + Mutations.UPDATE_PROCESS_ORDER, payload);
    },
    [Actions.UPDATE_DEVICE_ID](context, payload) {
      context.commit("auth/" + Mutations.UPDATE_DEVICE_ID, payload);
    },
    [Actions.UPDATE_SECRET_KEY](context, payload) {
      context.commit("auth/" + Mutations.UPDATE_SECRET_KEY, payload);
    },
    [Actions.UPDATE_USER_CONFIGS](context, payload) {
      context.commit("page/" + Mutations.UPDATE_USER_CONFIGS, payload);
    },
    [Actions.UPDATE_CONFIGS](context, payload) {
      context.commit("page/" + Mutations.UPDATE_CONFIGS, payload);
    },
    [Actions.UPDATE_TRANS_ADDRESSES](context, payload) {
      context.commit("order/" + Mutations.UPDATE_TRANS_ADDRESSES, payload);
    },
  },
});

export default store;
