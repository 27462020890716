import Inputmask from "inputmask";
import { convertStringToNumber } from "@/core/helpers/utils";

export default function initInputmask(element, alias = "currency") {
  let isInputSelect = false;
  const opts = {
    alias: "currency",
    suffix: " đ",
    prefix: "",
    digits: 0,
    groupSeparator: ",",
    removeMaskOnSubmit: true,
    autoUnmask: true,
    allowMinus: false,
    rightAlign: false,
  };
  if (alias == "currency") {
    opts["onBeforePaste"] = (value) => {
      return convertStringToNumber(value);
    };
    element.forEach((item) => {
      item.addEventListener("paste", (event) => {
        if (isInputSelect) {
          event.target.value = "";
        }
      });
      item.addEventListener("select", (event) => {
        isInputSelect =
          event.target.selectionStart != event.target.selectionEnd;
      });
    });
  }
  const im = new Inputmask(opts);
  im.mask(element);
}
