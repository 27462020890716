import _ from "lodash";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const CryptoJS = require("crypto-js");

export function convertProxyObjectToPojo(proxyObj) {
  return _.cloneDeep(proxyObj);
}

export function generateImageSrcFromBase64(string): string {
  return `data:image/png;base64, ${string}`;
}

export function numberWithCommas(number): string {
  if (!number) return "0";
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function removeWhiteSpace(str): string {
  return str.replace(/\s/g, "");
}

export function randomNumber(length = 6): number {
  return Math.floor(Math.random() * Math.pow(10, length));
}

export function removeSpecialCharacters(str): string {
  return str.replace(/^\\\/.,<>;:\[]{}|=\)\(*&\^%\$#@!~`/gi, "");
}

export function isFullLink(url): boolean {
  return url.startsWith("http");
}

export function convertStringToNumber(str): string {
  return str.replace(/[^\d]/g, "");
}

export function encryptWithAES(string) {
  return CryptoJS.AES.encrypt(
    string,
    process.env.VUE_APP_ENCRYPT_TEXT
  ).toString();
}

export function decryptWithAES(encryptedText) {
  try {
    const bytes = CryptoJS.AES.decrypt(
      encryptedText,
      process.env.VUE_APP_ENCRYPT_TEXT
    );
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  } catch (e: any) {
    return null;
  }
}
