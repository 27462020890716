import { Storages } from "@/main/enums/StorageEnums";
import UserProps from "@/store/modules/AuthModule/interfaces/UserProps";
import { decryptWithAES, encryptWithAES } from "@/core/helpers/utils";

export default class StorageClass {
  public static setValue(key, value): void {
    if (Array<string>("object", "array").indexOf(typeof value) !== -1) {
      value = JSON.stringify(value);
    }
    if (value) {
      value = encryptWithAES(value);
    }
    window.localStorage.setItem(key, value);
  }

  public static getValue(key): null | string {
    const value = window.localStorage.getItem(key);
    return value ? decryptWithAES(value) : value;
  }

  public static deleteValue(key): void {
    window.localStorage.removeItem(key);
  }

  public static getUser(): null | UserProps {
    const value = this.getValue(Storages.USER);
    if (value && value != "undefined") {
      return JSON.parse(value);
    }
    return null;
  }

  public static setUser(user): void {
    window.localStorage.setItem(
      Storages.USER,
      encryptWithAES(JSON.stringify(user))
    );
  }
}
