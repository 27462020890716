import * as toastr from "toastr";

toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: true,
  progressBar: false,
  positionClass: "toast-top-center",
  preventDuplicates: false,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "3000",
  extendedTimeOut: "3000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};

export default class Notify {
  public static success(msg, tl = "", cfgOvr = {}): void {
    this.notify("success", msg, tl, cfgOvr);
  }

  public static warning(msg, tl = "", cfgOvr = {}): void {
    this.notify("warning", msg, tl, cfgOvr);
  }

  public static error(msg, tl = "", cfgOvr = {}): void {
    this.notify("error", msg, tl, cfgOvr);
  }

  public static notify(lvl, msg, tl, cfgOvr): void {
    toastr[lvl](msg, tl, cfgOvr);
  }

  public static remove(): void {
    toastr.remove();
  }
}
