
import { defineComponent } from "vue";
import { removeModalBackdrop } from "@/core/helpers/dom";

export default defineComponent({
  name: "Auth",
  setup() {
    removeModalBackdrop();
  },
});
