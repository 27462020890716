enum Actions {
  UPDATE_ACCESS_TOKEN = "update_access_token",
  UPDATE_USER = "update_user",
  UPDATE_PAGE_TITLE = "update_page_title",
  UPDATE_ERROR = "update_error",
  UPDATE_ORDER_LIST = "update_order_list",
  UPDATE_ORDER_DETAIL = "update_order_detail",
  UPDATE_PROCESS_ORDER = "update_process_order",
  UPDATE_DEVICE_ID = "update_device_id",
  UPDATE_SECRET_KEY = "update_secret_key",
  UPDATE_USER_CONFIGS = "update_user_configs",
  UPDATE_CONFIGS = "update_configs",
  UPDATE_TRANS_ADDRESSES = "update_trans_addresses",
  UPDATE_LIST_PROCESSING = "update_list_processing",
}

enum Mutations {
  UPDATE_ACCESS_TOKEN = "update_access_token",
  UPDATE_USER = "update_user",
  UPDATE_PAGE_TITLE = "update_page_title",
  UPDATE_ERROR = "update_error",
  UPDATE_ORDER_LIST = "update_order_list",
  UPDATE_ORDER_DETAIL = "update_order_detail",
  CLEAR_ORDER_LIST = "clear_order_list",
  UPDATE_PROCESS_ORDER = "update_process_order",
  UPDATE_DEVICE_ID = "update_device_id",
  UPDATE_SECRET_KEY = "update_secret_key",
  UPDATE_USER_CONFIGS = "update_user_configs",
  UPDATE_CONFIGS = "update_configs",
  UPDATE_TRANS_ADDRESSES = "update_trans_addresses",
  UPDATE_LIST_PROCESSING = "update_list_processing",
}

export { Actions, Mutations };
