import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import UserService from "@/main/services/UserService";
import LogIn from "@/views/auth/LogIn.vue";
import Dashboard from "@/views/Dashboard.vue";
import OrderList from "@/views/order/OrderList.vue";
import PasswordChange from "@/views/user/PasswordChange.vue";
import Contact from "@/views/user/Contact.vue";
import Policy from "@/views/user/Policy.vue";
import Info from "@/views/user/Info.vue";
import CreateWithdraw from "@/views/order/CreateWithdraw.vue";
import Auth from "@/layout/Auth.vue";
import OrderDetail from "@/views/order/OrderDetail.vue";
import store from "@/store";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import CreateCashTransfer from "@/views/order/CreateCashTransfer.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import RouteMiddleware from "@/router/middlewares/RouteMiddleware";
import OrderEnums from "@/main/enums/OrderEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    name: "home",
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
          title: "Trang chủ",
        },
        beforeEnter: (to, from, next) => {
          UserService.getOrderProcess((data) => {
            if (data.length > 0) {
              const listProcessing: Array<number> = [];
              for (let i = 0; i < data.length; i++) {
                listProcessing.push(data[i].id);
              }
              store.dispatch(Actions.UPDATE_PROCESS_ORDER, {
                detail: data[0],
              });
              store.commit("order/" + Mutations.UPDATE_LIST_PROCESSING, {
                listProcessing: listProcessing,
              });
            }
            next();
          });
        },
      },
      {
        path: "/password-change",
        name: "password-change",
        component: PasswordChange,
        meta: {
          title: "Thay đổi mật khẩu",
        },
      },
      {
        path: "/contact",
        name: "contact",
        component: Contact,
        meta: {
          title: "Liên hệ hỗ trợ",
        },
      },
      {
        path: "/policy",
        name: "policy",
        component: Policy,
        meta: {
          title: "Chính sách điều kiện",
        },
      },
      {
        path: "/user/info",
        name: "info",
        component: Info,
        meta: {
          title: "Thông tin tài khoản",
        },
      },
      {
        path: "/orders",
        name: "order_list",
        component: OrderList,
        meta: {
          title: "Danh sách đơn",
        },
      },
      {
        path: "/order/create-cash-transfer",
        name: "create_cash_transfer",
        component: CreateCashTransfer,
        meta: {
          title: "Nộp tiền mặt vào tài khoản",
          type: OrderEnums.OrderTypes.CASH_TRANSFER,
        },
      },
      {
        path: "/order/create-withdraw",
        name: "create_withdraw",
        component: CreateWithdraw,
        meta: {
          title: "Rút tiền mặt",
          type: OrderEnums.OrderTypes.WITHDRAW,
        },
      },
      {
        path: "/order/detail/:id",
        name: "order_detail",
        component: OrderDetail,
        beforeEnter: (to, from, next) => {
          if (!to.params.id) {
            router.push("order");
          } else {
            UserService.getOrder(to.params.id, (data) => {
              store.dispatch(Actions.UPDATE_ORDER_DETAIL, {
                detail: data,
              });
              next();
            });
          }
        },
      },
    ],
  },
  {
    path: "/",
    component: Auth,
    children: [
      {
        path: "login",
        name: "login",
        component: LogIn,
        meta: {
          title: "Đăng nhập",
        },
      },
      {
        path: "forgot-password",
        name: "forgot_password",
        component: ForgotPassword,
        meta: {
          title: "Quên mật khẩu",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  RouteMiddleware.validate(to, from, next);
});

router.afterEach((to, from) => {
  RouteMiddleware.afterNavigation(to, from);
});
export default router;
