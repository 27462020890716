enum OrderStatuses {
  NEW = 0,
  COMPLETED = 1,
  WAITING_AGENT = 2,
  AGENT_ACCEPTED = 3,
  QR_CODE_CREATED = 6,
  AGENT_RECEIVED = 7,
  CANCELLED = 9,
  WAIT_USER_TRANSFER = 10,
  USER_TRANSFERRED = 11,
  AGENT_TRANSFERRED = 12,
  SYSTEM_TRANSFERRED = 13,
  AGENT_CONFIRM_TRANSFERRED = 14,
  USER_CONFIRM_RECEIVED = 15,
}

enum OrderTypes {
  CASH_TRANSFER = 1,
  WITHDRAW = 5,
}

enum AgentTypes {
  SIH = 1,
  CP = 3,
}

export default { OrderStatuses, OrderTypes, AgentTypes };
