import $ from "jquery";

const removeModalBackdrop = () => {
  if (document.querySelectorAll(".modal-backdrop.fade.show").length > 0) {
    document.querySelectorAll(".modal-backdrop.fade.show").forEach((item) => {
      item.remove();
    });
  }
  if ($("body").hasClass("modal-show")) {
    $("body").removeClass("modal-show");
    $("body").css({
      overflow: "auto",
      paddingRight: 0,
    });
  }

  $("#app").removeClass("aside-active");
};

export { removeModalBackdrop };
