const API_KEY = process.env.VUE_APP_GOOGLE_MAP_API_KEY || "";
const CALLBACK = "initMap";
let resolveInitPromise;
let rejectInitPromise;
// This promise handles the initialization
// status of the google maps script.
const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve;
  rejectInitPromise = reject;
});
window[CALLBACK] = resolveInitPromise;

export default function init() {
  if (!Object.prototype.hasOwnProperty.call(window, "google")) {
    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&callback=${CALLBACK}`;
    script.onerror = rejectInitPromise;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.querySelector("head").appendChild(script);
  }
  return initPromise;
}
