import FingerPrintJS from "@fingerprintjs/fingerprintjs-pro";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import Notify from "@/core/helpers/notify";
import UserService from "@/main/services/UserService";

export function getDeviceID(callback): void {
  if (!process.env.VUE_APP_FINGERPRINT_JS_KEY) {
    Notify.error("Mising config key!");
  } else {
    FingerPrintJS.load({
      apiKey: process.env.VUE_APP_FINGERPRINT_JS_KEY,
      region: "ap",
    })
      .then((fp) => fp.get())
      .then((result) => {
        store.dispatch(Actions.UPDATE_DEVICE_ID, result.visitorId);
        UserService.registerDevice(() => {
          callback();
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
