import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import router from "@/router";
import { MqttProps } from "@/core/services/MqttClient";

export function clearLoginInfo(
  mqttClient: MqttProps,
  preventBroadcast?: boolean
): void {
  store.dispatch(Actions.UPDATE_ACCESS_TOKEN, null);
  mqttClient.unsubscribe(preventBroadcast);
  router.push({
    name: "login",
  });
}

export function setLoginInfo(data): void {
  store.dispatch(Actions.UPDATE_ACCESS_TOKEN, data.data.access_token);
  store.dispatch(Actions.UPDATE_USER, data.data.user);
}
