import sha1 from "sha1";
import store from "@/store";

export default class HashHelper {
  public static encode(data): string {
    return sha1(this._convertToString(data) + "|" + store.state.auth.secretKey);
  }

  private static _convertToString(object, separator = "|"): string {
    const keys = Object.keys(object).sort();
    const array: Array<string> = [];
    for (let i = 0; i < keys.length; i++) {
      const item = object[keys[i]];
      if (item || item === 0) {
        if (Array.isArray(item)) {
          if (item.length > 0) {
            array.push(this._convertToString(item, ","));
          }
        } else if (typeof item == "object") {
          array.push("[object Object]");
        } else {
          array.push(item);
        }
      }
    }
    return array.join(separator);
  }
}
